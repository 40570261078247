.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.selected {
  text-decoration: underline !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
      background-color: #fff !important;
      color: #c2a01e;
      border-bottom: 1px solid #c2a01e;
}

.mirai-MuiSvgIcon-root {
  color: #c2a01e !important;
}


.mirai-header-text {
  color: #c2a01e !important;
}

.mirai-cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
}

.MuiPaper-root {
  border-radius: 0px;
  box-shadow: 0px 0px 0px #fff !important;
  /* border-bottom: 1px dotted #ccc; */
}


.css-17dcqu8-MuiTypography-root-MuiLink-root {
  color: #c2a01e !important; 
}


.mirai-button {
  background-color: #c2a01e !important; 
}

.mirai-outline-button {
  border: 1px solid #c2a01e !important; 
  border-radius: 25px;
  color: #c2a01e !important; 
}

.mirai-link{
  color: #c2a01e !important; 
  text-decoration-color: #c2a01e !important; 
}

.mirai-link > a:link {
  text-decoration-color: #c2a01e !important; 
}

.css-6bxval {
  padding-top: 64px;
  padding-bottom: 0 !important;
}

.mirai-align-right {
  text-align: justify;
  float: right;
}

.css-ah0e1x-MuiPaper-root {
  border-radius: 0px;
  box-shadow: none;
}

.logo-img{
  width:30%;
  height: auto;
}

.card-image{
  height: 240px;
}


.whatsapp_widget_footer_btn {
  color:#fff !important;
}

.gallery-box {
  position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: '60%';
    background-color: 'background.paper';
    opacity:0.2;
    border: '1px solid #fff'
}


@media screen and (max-width: 820px) {
  .nav-book-now{
    display:block !important;
  }

 
}




@media screen and (max-width: 600px) {
  .mirai-cards-list {
    display: block;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
  }
 
  
  .css-x6ilej {
    padding-top: 0px !important;
  }

  .mirai-service-title{
    margin-bottom: 1px !important;
  }

  .mirai-button{
    width:110px;
  }

  .logo-img{
    width:60%;
  }
  
  .break-line{
    display:block;
  }
  .nav-book-now{
    display:block !important;
  }
}

