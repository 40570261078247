body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.whatsapp_widget_wrapper {
	position: fixed;
	z-index: 99999999;
	display: flex;
	flex-direction: column;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.whatsapp_widget_chat_wrapper {
	width: var(--widget-width);
	padding: 15px;
	display: -webkit-box;
	display: flex;
}
@media (max-width: 360px) {
	.whatsapp_widget_chat_wrapper {
		width: var(--widget-width-mobile);
	}
}
.whatsapp_widget_chat_wrapper_inner {
	position: relative;
	background-color: #fff;
	padding: 10px;
	box-shadow: 0px 1px 0.5px rgba(0, 0, 0, 0.2);
	border-top-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.whatsapp_widget_chat_wrapper_arrow {
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	top: 0;
	left: -12px;
	width: 12px;
	height: 19px;
}
.whatsapp_widget_footer {
	background-color: #fff;
	padding: 12px 15px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	width: var(--widget-width);
}
@media (max-width: 360px) {
	.whatsapp_widget_footer {
		width: var(--widget-width-mobile);
	}
}
.whatsapp_widget_footer_btn {
	text-decoration: none;
	padding: 8px 12px;
	border-radius: 2px;
	border: none;
	font-size: 15px;
	line-height: 20px;
	cursor: pointer;
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
	overflow: hidden;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.whatsapp-body {
    background: #f5f7f9;
    border-left: 2px solid #2db742;
    border-radius: 2px 4px 2px 4px;
    -webkit-border-radius: 2px 4px 2px 4px;
    -moz-border-radius: 2px 4px 2px 4px;
    display: table;
    position: relative;
    text-decoration: none;
    width: 100%;
    font-weight: 600;
}

.whatsapp-body--li {
    background: #f5f7f9;
    text-decoration: none;
    width: 100%;
    list-style: none;
   
}

a:visited {
 
  text-decoration: none;
}


.whatsapp_widget_header {
	padding: 15px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	width: var(--widget-width);
}
@media (max-width: 360px) {
	.whatsapp_widget_header {
		width: var(--widget-width-mobile);
	}
}
.whatsapp_widget_header_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	width: 48px;
	height: 48px;
	margin-inline-end: 15px;
}
.whatsapp_widget_header_close_btn {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	opacity: 0.6;
}
.whatsapp_widget_icon {
	padding: 10px;
	cursor: pointer;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 100%;
	border: solid 1px #eee;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.whatsapp_widget_pop {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	margin-bottom: 10px;
	transition: all 0.2s ease-in-out;
}
.whatsapp_widget_pop.close {
	opacity: 0;
	height: 0;
	overflow: hidden;
}
.whatsapp_widget_pop.open {
	opacity: 1;
}
.whatsapp_widget_input {
	display: inline-block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 4rem;
	outline: none;
	margin-bottom: 10px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.whatsapp_widget_bubble_anim {
	background-color: #fff;
	padding: 0px;
	width: 70px;
	height: 40px;
	position: relative;
	border-radius: 30px;
}
.whatsapp_widget_bubble_anim_inner {
	position: absolute;
	width: 70px;
	height: 40px;
	top: -10px;
	left: 18px;
}


